import SelectWithIcon from "@/components/shared/SelectWithIcon";
import { Filter, PropertyType } from "@/services/types";
import useGetOptionValue from "@/services/property/useGetOptionValue";
import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useDebouncedSearch } from "../../hooks/useDebouncedSearch";
import { Rule } from "antd/es/form";
import useFilterColorOption from "../../hooks/useFilterColorOption";

type OptionFilterProps = {
  prefixIcon?: React.ReactNode;
  placeholder?: string;
  property: PropertyType;
  onFilterChange?: (filter: Filter | string) => void;
  defaultValue?: string | string[] | null;
  operator?: string;
  type?: string;
  mode?: "multiple";
  maxTagCount?: "responsive" | number;
  style?: React.CSSProperties;
  formItemName?: (string | number)[] | string;
  label?: string;
  rules?: Rule[];
  hasMargin?: boolean;
};

export const OptionFilter = (props: OptionFilterProps) => {
  const { search, onSearch } = useDebouncedSearch();
  const {
    property,
    onFilterChange,
    defaultValue,
    operator = "EQUAL",
    type = "singleoptionproperty",
    formItemName,
    label,
    rules,
    hasMargin = false,
    ...restProps
  } = props;
  const { data: optionValue } = useGetOptionValue(property?.id, search);
  const [filterValue, setFilterValue] = useState<string | string[] | null>(
    null
  );
  const filterOptions = useFilterColorOption(optionValue);

  const handleFilterChange = (value: string | string[]) => {
    setFilterValue(value);
    const listOptionValue = optionValue?.data.results ?? [];
    const option = !props.mode
      ? listOptionValue.find((item) => item.id === value)
      : value.length > 0;
    if (option) {
      const filter = {
        property: property.id,
        type: type,
        slug: property.slug,
        name: property.name,
        operator: operator,
        value:
          restProps.mode === "multiple" || type === "multioptionproperty"
            ? [...value]
            : value,
        label: option?.value,
        color: option?.color,
      };
      onFilterChange && onFilterChange(filter);
    } else {
      onFilterChange && onFilterChange(property.slug);
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setFilterValue(defaultValue);
    } else {
      setFilterValue(null);
    }
  }, [defaultValue]);

  return (
    <>
      <Form.Item
        name={formItemName}
        style={!hasMargin ? { margin: 0 } : {}}
        label={label}
        rules={rules}>
        <SelectWithIcon
          {...restProps}
          // style={{ width: 129 }}
          options={filterOptions}
          onChange={handleFilterChange}
          allowClear
          value={filterValue}
          filterOption={false}
          placement="bottomLeft"
          showSearch
          popupMatchSelectWidth={false}
          optionFilterProp="label"
          onSearch={onSearch}
          onFocus={() => onSearch(undefined)}
        />
      </Form.Item>
    </>
  );
};

export default OptionFilter;
